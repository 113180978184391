.clickable {
  cursor: pointer;
}
.icon-row {
  width: 2em;
}
.icon-folder {
  width: 1em;
}
.icon-file {
  width: 0.75em;
}
.image-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.image-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.ql-container {
  overflow: visible;
}
.ql-pdf-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 8px 12px;
  white-space: nowrap;
  position: absolute;
  transform: translateY(10px);
}

.ql-pdf-tooltip.ql-flip {
  transform: translateY(-10px);
}

.ql-pdf-tooltip a {
  cursor: pointer;
  text-decoration: none;
}

.ql-pdf-tooltip .ql-action {
  padding-right: 8px;
  border-right: 1px solid #ccc;
}

.ql-pdf-tooltip .ql-remove {
  padding-left: 8px;
}

pdf {
  color: var(--bs-link-color);
  text-decoration: underline;
}